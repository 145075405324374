
.label h4{
    font-size: 14px !important;
    margin-left:20px;
    margin-top:3  0px;
}
.label h4 strong{
    font-weight: bold;
}
.rotulo{
    margin:20px;
    min-height: 300px;
}
  .R500{
      display: block;
      height:400px;
  }
  .enderecoRotulo{
    position:absolute;  
    font-size: 8px;
    text-align: center;
  }
  .baseRotuloPosition{
    position: absolute;;
    left: 0;
  }
  .qrcode{
    position: absolute;
  }
  .nameRotulo{
    position: absolute;
    text-align: center;
    font-size:10px;
  }
  .nameRotulo strong{
      font-weight: bold;;
  }
  .nameRotulo .iconName{
      width: .6cm;
      height: .6cm;
  }
  .nameRotulo .detailNameRotulo{
    border:#000 solid 1px;
    width: 40%;
    display: block;
    margin:35px 25px;
    height: 2px;
  }
  .nameRotulo .iconDosagem{
    width: .7cm;
    height: .7cm;
    margin-top:10px;
  }

  .posologia{
    margin-left: 30px;
  }
  .posologia h3 {
    margin-top:5px;
  }
  .posologia h3, .posologia h4{
    font-size:10px;
    font-weight: bold;

  }
  .posologia{
    font-size:10px;
    display: block;
    position: absolute;
    top:0;
    right: 0;
  }

  .button-white{
    background: #FFF;
    color: #000;
    font-weight: bold;
    border-radius: 99px;
  }
  .print-button{
    text-align: center;
  }
  .button-print-print{
    margin-top:50px;
    width: 400px;
  }