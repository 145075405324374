body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: sen;
  src: url('fonts/Sen-Regular.ttf');
}
@font-face {
  font-family: sen-bold;
  src: url('fonts/Sen-Bold.ttf');
}
#root{
  height: 100% !important;
}
.full-height{
  height: 100% !important;
}
*{
  margin:0;
  padding:0;
  font-family: 'Sen', Arial, Helvetica, sans-serif;
}
html{
  height: 100%;
}
body {
  height: 100%;
}
textarea { 
  text-transform: uppercase; 
}
.card-login{
  margin-top:100px;
  left:50%;
  width: 350px;
  height: 450px;
  transform: translate(-50%);
  border-radius: 7px 7px 7px 7px;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(150,150,150,1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(150,150,150,1);
  box-shadow: 0px 0px 15px 0px rgba(150,150,150,1);
}
.center{
  text-align: center;
  position: relative;
}
.ic-logo{
  width: 51px;
  height: 50px;
  display: block;
  background: url('images/almofariz_icones.png') no-repeat
}

.center-position{
  height:50px;
  display: block;
}
.ic-logo-position{
  position: absolute;
  left:50%;
  margin-left:-25px;
}

.card-login h3{
  transform: matrix(1, 0, 0, 1, 0, 0);
  text-align: center;
  letter-spacing: 0px;
  color: #111111;
  opacity: 1;
  font-size: 24px;
  font-weight: bold;
}
.card-login .input-field label{
  text-align: center;
  width: 100%;
  margin: 0px;
  left: 0;
}
.base-login{
  margin-top:60px;
}
.custon-button{
  width:100%;
  background: #7EB288 !important;
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 0px solid #000000;
  font-weight: bold;
  margin-top:30px
}
.logo{
  background: url('images/almofariz_logo.png') no-repeat center;
  background-size: 185px 27px;
  height:55px;
  width: 100%;
}
.display-error{
  color: red;
  text-align: center;
  margin-top: 15px;
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Style the header */
header {
  padding: 30px;
  text-align: center;
  height:150px;
  font-size: 35px;
  background: white;
}

/* Container for flexboxes */
main {
  display: -webkit-flex;
  display: flex;
  min-height: 700px;
  background: #BFD2BB;
}

/* Style the footer */
footer {
  padding: 10px;
  text-align: center;
  color: white;
  height:80px; 
}

header h5{
  color:#000;
  font-weight: bold;
}
.icon{
  display: block;
  height: 50px;
  width: 50px;
  margin: auto;
}
.icon-book{
  background: url('images/icon_book.png') no-repeat center;
  background-size: cover;
}
.icon-pen{
  background: url('images/icon_pen.png') no-repeat center;
  background-size: cover;
}
.button-pen{
  margin-top:10% !important;
}
.button:hover{
  color: #7EB288;
}
.button{
  text-align: center;
  width: 300px !important;
  display: block;
  margin: auto;
  margin-top:5%;
  cursor: pointer;
  color: #000;
}
.button-book{
  margin-top:10%;
}
.button h5{
  background: #FFF;
  text-align: center;
  border-radius: 99px;
  height:40px;
  line-height:40px;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;;
}
.detail-page{
  font-size: 20px;
  text-align: center;
  width: 400px;
  font-weight: bold;
  border-radius: 99px;
  background: #7EB288;
  color: #FFF;
  height:50px;
  line-height:50px;
  margin: auto;
  margin-top:-23px
}
.base-consult{
  width: 100%;
  position: relative;
}
.input-code-consult{
  background: #FFF !important;
  border-radius: 99px !important;
  width: 500px !important;
  text-align: center;
  position: absolute;
  left:50%;
  margin-left:-250px !important;
  top:250px;
  font-size: 20px !important;
  font-weight: bold;
}
.button-code-consult{
  position: absolute;
  left:50%;
  width: 200px;
  margin-left:-100px;
  top:320px;
  background: #FFF;
  color:#000;
  border-radius: 25px;
  font-weight: bold;
}
.base-label{
  min-height:500px;
  margin-top:30px;
}
.image-report{
  float:left;
  width:30%;
  min-height:500px;
  position:relative;
}
.report{
  width:280px;
  height:400px;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-140px;
  margin-top:-200px;
}

.edit{
  text-align: center;
  background:#FFF;
  border-radius: 10px;
  padding-bottom: 20px;
}
.edit h4{
  font-size:18px;
  text-align: left;
  font-weight: bold;
}
.edit textarea{
  min-height:170px;
  border: #CCC solid 1px;
  padding:5px;
  border-radius: 10px;
}
.edit-posologia{
  min-height:200px;
  margin:10px;
  padding:20px;
}
.edit-formula{
  min-height:200px;
  margin:10px;
  padding:20px;
}
.edit Button{
  margin-top:5px;
}

.posologiaText{
  min-height: 4cm;
  width: 4.5cm;
}
.posologia{
  
}

.posologia .strong{
  font-weight: bold !important;
}
.posologia h3{
  font-size:12px;
  margin:3px 0;
  font-weight: normal !important;
}
.posologia h6{
  font-size:12px;
  margin:0;
}

.posologia .nameDr{
  margin-top:0px;
}
.sizeMidia{
  border:#ccc solid 1px;
  width: 100%;
  height: 850px;
  margin-top:0px;
  padding:15px;
}
.showMidia{
  display: block;
}
.hideMidia{
  display: none
}
.base-midias{
  margin-top:30px;
  
  display:flex;
}
.base-midias .midias{
  flex: 100;
}
.base-midias .arrow-left-midias{
  flex: 1;
  margin-top: 55%;
  margin-left: 10px;
  cursor: pointer;
  font-size:50px;
  font-weight: bold;
}
.base-midias .arrow-left-midias:hover{
  color: #7EB288;
}
.base-midias .arrow-right-midias:hover{
  color: #7EB288;
}
.base-midias .arrow-right-midias{
  flex: 1;
  margin-top: 55%;
  cursor: pointer;
  font-size:50px;
  font-weight: bold;
}
.base-midias span{
  padding-top: 10px;
}

.confirm{
  text-align: center;
  border-radius: 10px;
  margin-top:30px;
}
.confirm select{
  display: block;
  border: #ccc solid 1px;
  border-radius: 5px;
}
.edit-canvas{
  margin-top: 30px;
  padding:30px;
  flex: 1 1 0%;
  background: #FFF;
  margin-top: 90px;
  border-radius: 5px;
  height: 835px;
  margin-left:20px;

}
.edit-canvas h6{
  text-align: left;
}
.edit-canvas textarea{
  border:#ccc solid 1px;
  border-radius: 5px;
  min-height:200px;
  padding:10px;
}
.viewPDF{
  border:0;
  width: 80%;
  min-height: 450px;
}

.label{
  text-align: center;
  min-height:500px;
  margin-top:80px;
}

.colors{
  margin:10px;
}
.colors button{
  border: 0;
  width:200px;
  border-radius: 5px;
  height:40px;
  margin:5px;
}
.colors .selected{
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}
.colors .show{
  display: inline-block !important;
}
.colors .green{
  background-color: #7EB288 !important;
}
.colors .purple{
  background-color: #C5B8FF !important;
}
.colors .blue {
  background-color: #68BCFF !important;
}
.popupclose-title{
  height:100px;
  background: #7DB387;
  font-size: 20pt;
  padding: 10px;
  color: #FFF;
  font-weight: bold;
  border-radius: 5px;
  
}
.popupclose-close{
  color: red;
  line-height: 35px;
  font-size: 23px;
  border: #000 solid 2px;
  border-radius: 999px;
  height: 40px;
  width: 41px;
  display: inline-block;
  margin-top: 18px;
  cursor: pointer;
}
.lds-dual-ring {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-top: 0px;;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 84px;
  height: 84px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.green-btn{
  background-color: #7EB288;
  color: #FFF;
}
.buttonBack{
  position: absolute;
  left: 15px;
  top:15px;
  font-size: 70px;
  color: #BFD2BB;
}

@media only screen and (min-width: 993px){
  .container {
      width: 100% !important;
  }
}

@media only screen and (min-width: 601px){
  .container {
    width: 90% !important;
  }
  .container {
      margin: 0 auto;
      max-width: 90%;
      width: 90%;
  }
}
.FormulaView ul{
  height:200px;
  overflow: auto;
}
.FormulaView ul li{
  border:#333 solid 1px;
  text-align: left;
  padding-left: 10px;
  margin:5px 0px;
  border-radius: 3px;
  line-height: 25px;
  position: relative;
}
.FormulaView ul li a.removeItem{
  color:red
}
.FormulaView ul li span{
  color:red;
  position: absolute;
  right: 15px;
  cursor: pointer;
}
.FormulaView a{
  cursor: pointer;
}

.FormulaView input{
  all: revert;
  border:0px;
  width: 90%;
}
.FormulaView input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
}
